import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
const NotFoundPage = () => (
  <Layout>
     <div className="mx-6 notfound">
      <h1 className="mt-6 mb-4 text-5xl">404</h1>
      <p className="text-3xl my-2 mt-8">Oops! This Page Could Not Be Found</p>
      <p className="text-xl my-2 mb-8">Sorry but the page you are looking for does not exist, have been removed. Name changed or is temporarily unavailable</p>

      <Link class="bg-black hover:bg-grey-darker text-white font-bold py-4 px-4 my-2 inline-block rounded no-underline" to="/">
        Go to Homepage
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
